import { lighten, makeStyles } from '@material-ui/core/styles';

export const StyledUtils = makeStyles(({
  palette,
  ...theme
}) => ({
  '@global': {
    'body': {
      margin: 0,
      backgroundColor: '#ffffff !important',
      fontFamily: 'Montserrat sans-serif',
      webKitFontSmoothing: 'antialiased',
    },
    '.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6': {
      margin: '0 0 0.5rem',
      lineHeight: '1.6',
    },
    '.h1, h1': { fontSize: '38px', fontFamily: "Montserrat"},
    '.h2, h2': { fontSize: '1.75rem', fontFamily: "Montserrat" },
    '.h3, h3': { fontSize: '1.5rem', fontFamily: "Montserrat" },
    '.h4, h4': { fontSize: '1.25rem', fontFamily: "Montserrat" },
    '.h5, h5': { fontSize: '20px', fontFamily: "Montserrat" },
    '.h6, h6': { fontSize: '16px', fontFamily: "Montserrat"},
    '.p, p': { fontFamily: "Montserrat" },
    a: {
      textDecoration: 'none',
      color: 'inherit'
    },
    '.caption': { font: 'var(--font-caption)' },
    '.subtitle-1': { font: 'var(--font-subtitle-1)' },
    '.subtitle-2': { font: 'var(--font-subtitle-2)' },
    '.heading': { font: 'var(--font-heading)' },
    '.capitalize': { textTransform: 'capitalize !important' },
    '.font-normal': { fontWeight: 'normal !important' },
    '.font-light': { fontWeight: '300 !important' },
    '.font-medium': { fontWeight: '500 !important' },
    '.font-semibold': { fontWeight: '600 !important' },
    '.font-bold': { fontWeight: '700 !important' },
    '.text-10': { fontSize: '10px !important' },
    '.text-13': { fontSize: '13px !important' },
    '.text-14': { fontSize: '14px !important' },
    '.text-16': { fontSize: '16px !important' },
    '.text-18': { fontSize: '18px !important' },
    '.text-20': { fontSize: '20px !important' },
    '.text-22': { fontSize: '22px !important' },
    '.text-24': { fontSize: '24px !important' },
    '.text-28': { fontSize: '28px !important' },
    '.text-30': { fontSize: '30px !important' },
    '.text-32': { fontSize: '32px !important' },
    '.text-36': { fontSize: '36px !important' },
    '.text-40': { fontSize: '40px !important' },
    '.text-44': { fontSize: '44px !important' },
    '.text-48': { fontSize: '48px !important' },
    '.text-72': { fontSize: '72px !important' },
    '.text-small': { fontSize: '0.8125rem !important' },
    '.whitespace-pre-wrap': {
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word'
    },

    '.border-radius-8': {
      borderRadius: '8px !important',
      overflow: 'hidden'
    },

    // COLOR STYLING
    '.bg-primary': { background: `${palette.primary.main} !important` },
    '.bg-secondary': { background: `${palette.secondary.main} !important` },
    '.bg-white': {
      background: '#fff !important',
      color: 'inherit'
    },
    '.bg-light-gray': { background: 'rgba(0, 0, 0, 0.03) !important' },
    '.bg-light-dark': {
      background: '#212121',
      color: 'white'
    },
    '.hover-bg-primary': { transition: 'all 250ms' },
    '.hover-bg-primary:hover': {
      background: `${palette.primary.main} !important`,
      color: '#ffffff',
      backgroundColor: `${palette.primary.main} !important`,
      fallbacks: [{ color: 'white !important' }],
    },
    '.hover-bg-primary:hover [class^="MuiSvgIcon-"]': {
      fill: 'white !important',
    },
    '.bg-light-primary': {
      background: 'rgba(var(--primary), 0.15) !important',
    },
    '.bg-light-secondary': {
      background: `${lighten(palette.secondary.main, 0.85)} !important`,
    },
    '.bg-light-error': {
      background: `${lighten(palette.error.main, 0.85)} !important`,
    },
    '.section-bg-light-primary': { background: '#F7F8FA' },

    '.gen': {
      backgroundColor: '#010E28',
      color: '#fff',
      padding: '4px 0 2px 0',
    },
    '.text-black': { color: '#555555 !important' },

    '.text-primary': { color: `${palette.primary.main} !important` },
    '.text-green': { color: '#00ca35 !important' },
    '.text-secondary': { color: `${palette.secondary.main} !important` },

    '.card': { transition: 'all 0.3s ease' },
    '.card:hover': { boxShadow: theme.shadows[12] },
    '.card-title': {
      fontSize: '1rem',
      textTransform: 'capitalize',
      fontWeight: '500',
    },
    '.card-subtitle': {
      fontSize: '0.875rem',
      color: 'var(--text-muted)'
    },
    '.theme-dark .card-subtitle': { color: 'rgba(255, 255, 255, 0.54)' },
    '@media screen and (max-width: 767px)': {
      '.invisible-on-pc': { visibility: 'visible' },
      '.section-background-overly': {
        backgroundImage: `url(${'/assets/images/backgroundOverly.png'})`,
        backgroundPosition: 'bottom;',
        backgroundSize: 'cover',
      },
      '.section-with-background-image-primary': {
        backgroundImage: 'none !important',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '100% 105%;',
        position: 'relative',
        margin: 'auto',
        backgroundSize: '32%',
        padding: 'auto',
      },
      '.maas-bg-image-primary': {
        backgroundImage: 'none !important',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '100% 105%;',
        position: 'relative',
        margin: 'auto',
        backgroundSize: '32%',
        padding: 'auto',
      },
      '.gen': {
        backgroundColor: '#010E28',
        color: '#fff',
        zIndex: 9999,
        position: 'fixed',
      },
      '.gen-text': {
        lineHeight: '1.2 !important',
      },
    },
    '@media screen and (min-width: 1200px)': {
      '.hide-on-pc': { display: 'none !important' },
    },

    '@media screen and (min-width: 1800px)': {
      '.section-with-background-image-primary': {
        backgroundImage: `url(${'/assets/images/draasBackground.svg'})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '100% 105%;',
        position: 'relative',
        margin: 'auto',
        backgroundSize: '16%',
        padding: 'auto',
      },
      '.maas-bg-image-primary': {
        backgroundImage: `url(${'/assets/images/maasheader.svg'})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '100% 105%;',
        position: 'relative',
        margin: 'auto',
        backgroundSize: '16%',
        padding: 'auto',
      },
    },

    '@media screen and (max-width: 1024px)': {
      '.container': {
        padding: '0px 1rem',
        maxWidth: '960px !important',
        margin: '0 auto',
      },
    },
    '@media screen and (max-width: 1200px)': {
      '.show-on-pc': { display: 'none !important' },
    },
    '.VictoryContainer svg': { height: '100% !important' },
    '.box-shadow-none': { boxShadow: 'none !important' },
    '.circle-44': {
      height: '44px !important',
      width: '44px !important'
    },
    '.circle-32': {
      height: '32px !important',
      minHeight: '32px !important',
      width: '32px !important',
    },
    '.circle-32 .MuiFab-root': { minHeight: '32px !important' },
    '.circle-32 .MuiIcon-root': { fontSize: '13px !important' },
    '.cursor-pointer': {
      cursor: 'pointer',
    },
    '.cursor-move': {
      cursor: 'move',
    },

    // LANDING STYLE
    ".landing": {color: "#000000de", overflow: "hidden", fontFamily: "Montserrat",},
    ".landing p": {color: "#55555", lineHeight: 1.6, fontSize: "15px"},
    ".landing h1": {color: "#55555", lineHeight: 1.6},
    ".landing a": {textDecoration: "none"},
    ".landing div, .landing section": {boxSizing: "border-box"},
    ".section-intro": {paddingTop: "7.5rem !important"},
    '.section': {
      padding: '2.5rem 0',

      '& .section__header': {
        margin: '0 0 32px',
        h2: {
          fontSize: 32,
          fontWeight: 700,
          margin: '0 0 16px',
        },
        p: {
          fontSize: 16,
          maxWidth: '36rem',
          margin: 0,
        },
      },
    },
    '.container': {
      padding: '0px 1rem',
      maxWidth: '1170px',
      margin: '0 auto',
    },
    '.header': {
      position: 'fixed',
      width: '100%',
      top: '-10px !important',
      left: '0',
      right: '0',
      padding: '10px 0',
      background: '#ffffff',
      transition: 'padding 0.3s linear',
      zIndex: '999999',
    },
    '.header.header-fixed': {
      padding: '10px 0',
      background: '#ffffff',
      boxShadow: '0 0 1px rgb(0 0 0 / 5%), 0 4px 8px rgb(0 0 0 / 4%)',
      zIndex: '999',
    },
    '.header .header-container': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    '.header .header-container .brand': {
      display: 'flex',
      alignItems: 'center',
    },
    '.header .header-container .brand img': { maxHeight: '24px' },
    '.header .header-container ul.navigation': {
      listStyle: 'none',
      margin: '0 0 0 30px',
      padding: '0',
    },
    '.header .header-container ul.navigation li': { display: 'inline-block' },
    '.header .header-container ul.navigation li a': {
      display: 'flex',
      alignItems: 'center',
      color: '#555555',
      fontSize: '14px',
      padding: '12px 15px',
      borderRadius: '4px',
      transition: 'all 0.3s ease-in',
    },
    '.header .header-container ul.navigation li a:hover': {
      background: '#f0f0f0',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
      border: '1px solid #f0f0f0',
    },
    th: {
      textAlign: 'left',
      padding: '10px',
      borderBottom: '1px solid #f0f0f0'
    },
    td: {
      textAlign: 'left',
      padding: '10px',
      borderBottom: '1px solid #f0f0f0'
    },

    '.dropdown .dropbtn': {
      border: 'none',
      outline: 'none',
      // fontFamily: 'Montserrat',
      margin: '0',
    },
    '.dropdown-content': {
      display: 'none',
      position: 'absolute',
      backgroundColor: '#ffffff',
      minWidth: '160px',
      boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
      zIndex: '1',
    },
    '.dropdown-content a': {
      fontSize: '0.875rem',
      color: '#555555 !important',
      float: 'none',
      fontWeight: '300',
      padding: '12px 16px',
      textDecoration: 'none',
      display: 'block',
      textAlign: 'left',
    },
    '.dropdown-content a:hover': {
      backgroundColor: '#ddd',
    },
    '.dropdown:hover .dropdown-content': {
      display: 'block',
    },
    '.header .header-container .header__toggle': { display: 'none' },
    '@media only screen and (max-width: 767px)': {
      '.header': { display: 'none' },
    },

    '.descriptionCard': {
      padding: '1rem 2rem',
      [theme.breakpoints.down('md')]: {
        padding: '1rem 2rem',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '1rem',
      },
    },
    '.borderRounded': {
      borderRadius: 16,
    },
    '.img': {
      width: '100%',
      borderRadius: 16,
    },
    '.list': {
      borderBottom: '1px solid #EFEFEF',
      paddingTop: '20px',
      '&:nth-last-child(1)': {
        borderBottom: 'none',
      },
    },
    '.alignList': {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        display: 'grid',
      },
    },
    '.cardContent': {
      padding: '0px 24px',
      [theme.breakpoints.down('sm')]: {
        padding: '0px',
      },
    },
    '.titleBorder': {
      borderLeft: '5px solid #4977e5',
      marginRight: '10px',
      height: '10px',
    },
    '.categorySection': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',

    },

    // POSITION STYLING
    '.flex': { display: 'flex' },
    '.flex-column': {
      display: 'flex',
      flexDirection: 'column'
    },
    '.flex-wrap': { flexWrap: 'wrap' },
    '.justify-start': { justifyContent: 'flex-start !important' },
    '.justify-center': { justifyContent: 'center' },
    '.justify-end': { justifyContent: 'flex-end' },
    '.justify-between': { justifyContent: 'space-between !important' },
    '.justify-around': { justifyContent: 'space-around' },
    '.items-center': { alignItems: 'center' },
    '.items-start': { alignItems: 'flex-start' },
    '.items-end': { alignItems: 'flex-end' },
    '.items-stretch': { alignItems: 'stretch' },
    '.flex-grow': { flexGrow: '1' },
    '.overflow-auto': { overflow: 'auto !important' },
    '.overflow-hidden': { overflow: 'hidden !important' },
    '.overflow-unset': { overflow: 'unset !important' },
    '.overflow-visible': { overflow: 'visible !important' },
    '.scroll-y': {
      overflowX: 'hidden',
      overflowY: 'scroll'
    },
    '.relative': { position: 'relative' },
    '.position-bottom': {
      position: 'absolute',
      bottom: '0'
    },
    '.text-center': { textAlign: 'center' },
    '.align-middle': { verticalAlign: 'middle' },
    '.text-right': { textAlign: 'right' },
    '.text-left': { textAlign: 'left' },
    '.x-center': {
      left: '50%',
      transform: 'translateX(-50%)'
    },
    '.y-center': {
      top: '50%',
      transform: 'translateY(-50%)'
    },

    '.m-auto': { margin: 'auto !important' },
    '.mx-auto': {
      marginLeft: 'auto !important',
      marginRight: 'auto !important',
    },
    '.w-full': { width: '100%' },
    '.max-w-full': { maxWidth: '100% !important' },
    '.min-w-full': { minWidth: '100% !important' },
    '.w-full-screen': { width: '100vw' },
    '.pro-img-w': { maxWidth: '15%' },
    '.min-w-750': { minWidth: '750px' },
    '.max-w-320': { maxWidth: '320px' },
    '.max-w-450': { maxWidth: '450px' },
    '.max-w-750': { maxWidth: '750px' },
    '.max-w-550': { maxWidth: '550px' },
    '.max-w-600': { maxWidth: '600px' },
    '.max-h-500': { maxHeight: '500px' },
    '.max-w-770': { maxWidth: '770px' },
    '.max-w-720': { maxWidth: '705px' },
    '.max-w-220': { maxWidth: '220px' },
    '.max-w-120': { maxWidth: '120px' },
    '.min-h-full': { minHeight: '100% !important' },
    '.h-full': { height: '100% !important' },
    '.h-auto': { height: 'auto' },
    '.h-full-screen': { height: '100vh' },
    '.h-150px': { height: '150px !important' },
    '.font-height-heading': { lineHeight: '1' },
    ...generateMarginPadding(0, 25, 0.25, 'rem'),
    ...generateMarginPadding(1, 16, 1, 'px'),
    ...generateMarginPadding(-25, -1, 0.25, 'rem'),
    ...generateMarginPadding(-16, -1, 1, 'px'),
    ...generateHeightWidh(),
  },
}));

const generateMarginPadding = (
  start = 0,
  end = 25,
  increament = 0.25,
  unit = 'rem',
) => {
  const classList = {};

  for (let i = start; i <= end; i++) {
    classList[`.m-${i}${unit === 'px' ? 'px' : ''}`] = {
      margin: `${i * increament}${unit} !important`,
    };
    classList[`.mt-${i}${unit === 'px' ? 'px' : ''}`] = {
      marginTop: `${i * increament}${unit} !important`,
    };
    classList[`.mb-${i}${unit === 'px' ? 'px' : ''}`] = {
      marginBottom: `${i * increament}${unit} !important`,
    };
    classList[`.mr-${i}${unit === 'px' ? 'px' : ''}`] = {
      marginRight: `${i * increament}${unit} !important`,
    };
    classList[`.ml-${i}${unit === 'px' ? 'px' : ''}`] = {
      marginLeft: `${i * increament}${unit} !important`,
    };
    classList[`.mx-${i}${unit === 'px' ? 'px' : ''}`] = {
      marginLeft: `${i * increament}${unit} !important`,
      marginRight: `${i * increament}${unit} !important`,
    };
    classList[`.my-${i}${unit === 'px' ? 'px' : ''}`] = {
      marginTop: `${i * increament}${unit} !important`,
      marginBottom: `${i * increament}${unit} !important`,
    };

    classList[`.p-${i}${unit === 'px' ? 'px' : ''}`] = {
      padding: `${i * increament}${unit} !important`,
    };
    classList[`.pt-${i}${unit === 'px' ? 'px' : ''}`] = {
      paddingTop: `${i * increament}${unit} !important`,
    };
    classList[`.pb-${i}${unit === 'px' ? 'px' : ''}`] = {
      paddingBottom: `${i * increament}${unit} !important`,
    };
    classList[`.pr-${i}${unit === 'px' ? 'px' : ''}`] = {
      paddingRight: `${i * increament}${unit} !important`,
    };
    classList[`.pl-${i}${unit === 'px' ? 'px' : ''}`] = {
      paddingLeft: `${i * increament}${unit} !important`,
    };
    classList[`.px-${i}${unit === 'px' ? 'px' : ''}`] = {
      paddingLeft: `${i * increament}${unit} !important`,
      paddingRight: `${i * increament}${unit} !important`,
    };
    classList[`.py-${i}${unit === 'px' ? 'px' : ''}`] = {
      paddingTop: `${i * increament}${unit} !important`,
      paddingBottom: `${i * increament}${unit} !important`,
    };
  }

  return classList;
};

const generateHeightWidh = (
  start = 0,
  end = 400,
  increament = 4,
  unit = 'px',
) => {
  const classList = {};

  for (let i = start; i <= end; i += increament) {
    classList[`.w-${i}`] = {
      width: `${i}${unit} !important`,
    };
    classList[`.min-w-${i}`] = {
      minWidth: `${i}${unit} !important`,
    };
    classList[`.max-w-${i}`] = {
      maxWidth: `${i}${unit} !important`,
    };
    classList[`.h-${i}`] = {
      height: `${i}${unit} !important`,
    };
    classList[`.min-h-${i}`] = {
      minHeight: `${i}${unit} !important`,
    };
    classList[`.max-h-${i}`] = {
      maxHeight: `${i}${unit} !important`,
    };
  }

  return classList;
};
