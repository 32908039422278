import { StyledUtils } from './utils/StyledUtils';
import { MediaQuery } from './utils/mediaQuery';

const GlobalCss = ({ children }) => {
  StyledUtils();
  MediaQuery();
  return children;
};

export default GlobalCss;
