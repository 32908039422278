import React from 'react';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {Theme} from '../../theme';
import GlobalCss from '../../styles/GlobalCss';
import Footer from './Footer/Footer';
import TopBar from './TopBar/TopBar';
import {CssBaseline} from "@material-ui/core";

const Layout = ({children}) => {
  return (
    <MuiThemeProvider theme={Theme}>
      <CssBaseline/>
      <GlobalCss>
        <TopBar/>
        {children}
        <Footer/>
      </GlobalCss>
    </MuiThemeProvider>
  );
};
export default Layout;
