import { createMuiTheme } from '@material-ui/core/styles';

const themeOptions = {
  palette: {
    type: 'light',
    primary: { main: '#1e6beb' },
    secondary: { main: '#919bab' },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },

  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
    MuiCard: {
      root: {
        borderRadius: 8,
      },
    },
    MuiFab: {
      root: {
        textTransform: 'none',
      },
    },
    MuiTimelineItem: {
      missingOppositeContent: {
        '&:before': {
          display: 'none',
        },
      },
    },
    MuiExpansionPanel: {
      root: {
        '&:before': {
          display: 'none'
        },
        '&:hover': {
          boxShadow: "0 10px 35px rgb(0 0 0 / 6%) !important"
        }
      },
    },
    typography: {
      body1: {
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 400,
        fontSize: 16,
        color: "red"
      },
      body2: {
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 400,
        fontSize: 16,
        color: "red"
      }
    }
  },
};

export const Theme = createMuiTheme({
  ...themeOptions,
});
