import { makeStyles } from '@material-ui/core/styles';

export const MediaQuery = makeStyles(() => ({
  '@global': {

    '@media screen and (max-width: 767px)': {

      '.h1, h1': { fontSize: '1.9rem' },
      '.h2, h2': { fontSize: '1.75rem' },
      '.h3, h3': { fontSize: '1rem' },
      '.h4, h4': { fontSize: '1.25rem' },
      '.h5, h5': { fontSize: '1rem' },
      '.h6, h6': { fontSize: '0.875rem' },

      '.invisible-on-pc': { visibility: 'visible' },
    },

    '@media screen and (min-width: 1200px)': {
      '.hide-on-pc': { display: 'none !important' },
    },

    '@media screen and (max-width: 1200px)': {
      '.show-on-pc': { display: 'none !important' },
    },

    '@media only screen and (max-width: 767px)': {
      '.header': {
        width: 'var(--topbar-mobile-width)',
        height: '100vh',
        left: 'auto',
        right: '0',
        padding: '20px 0 !important',
        boxShadow:
          '-2px 0 4px rgba(0, 0, 0, 0.14), -2px 4px 8px rgba(0, 0, 0, 0.28) !important',
        transition: 'all 0.3s ease-in-out',
      },
      '.header.closed': {
        right: 'calc(-1 * var(--topbar-mobile-width) - 5px)',
      },
      '.header.closed .header__toggle': {
        color: 'rgba(0, 0, 0, 0.87)',
        background: '#ffffff',
        boxShadow:
          '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
      },
      '.header .header__toggle': {
        top: '10px',
        right: '15px',
        color: 'rgba(0, 0, 0, 0.87)',
        position: 'fixed',
        display: 'inline-block !important',
        transition: 'all 0.3s ease-in-out',
      },
      '.header .header-container': {
        flexDirection: 'column',
        padding: '0',
        height: '100%',
      },
      '.header .header-container .brand': {
        padding: '0 20px 20px',
        width: '100%',
      },
      '.header .header-container ul.navigation': { margin: '0', width: '100%' },
      '.header .header-container ul.navigation li': { display: 'block' },
      '.header .header-container ul.navigation li a': {
        padding: '15px 20px',
        borderRadius: '0',
      },
    },

  },
}));
